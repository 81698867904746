/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query Name {\n    me {\n      id\n      firstName\n      lastName\n    }\n  }\n": types.NameDocument,
    "\n  fragment IApplicantFragment on Applicant {\n    id\n    firstName\n    lastName\n    position\n    email\n    emailVerified\n  }\n": types.IApplicantFragmentFragmentDoc,
    "\n  query ApplicationFileApplicants($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      applicants(options: { perPage: 1000 }) {\n        items {\n          ...IApplicantFragment\n        }\n      }\n    }\n  }\n": types.ApplicationFileApplicantsDocument,
    "\n  query ApplicationFileConfiguredIntegrations($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      configuredIntegrations\n    }\n  }\n": types.ApplicationFileConfiguredIntegrationsDocument,
    "\n  query ApplicationFileMilestones($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      milestones {\n        id\n        key\n        name\n        active\n      }\n    }\n  }\n": types.ApplicationFileMilestonesDocument,
    "\n  fragment ITaskFragment on Task {\n    id\n    key\n    typeName\n    title\n    state\n    isAlerted\n    isFrozen\n    isNew\n    isVisible\n    isStarted\n    common\n    custom\n    blueprintIndex\n    alertMessage\n    primaryApplicantId\n    meta {\n      title\n      requiresAuthentication\n      requiresEmailVerification\n      description {\n        default\n        open\n        locked\n        completed\n      }\n      disclaimer\n      groupKey\n      nextButtonLabelWhenCompleted\n      customCompletion\n      completionDescription\n      completionContinueButtonLabel\n    }\n    dueDate\n  }\n": types.ITaskFragmentFragmentDoc,
    "\n  query ApplicationFileTasks($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      tasks {\n        ...ITaskFragment\n      }\n    }\n  }\n": types.ApplicationFileTasksDocument,
    "\n  query ApplicationFile($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      voeIntegrationType\n      voaIntegrationType\n      milestones {\n        id\n        key\n        name\n        active\n      }\n      applicants {\n        items {\n          ...IApplicantFragment\n        }\n      }\n      tasks {\n        ...ITaskFragment\n      }\n      blueprint {\n        id\n        json\n      }\n    }\n  }\n": types.ApplicationFileDocument,
    "\n  query ApplicationFiles($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            detailedDescription\n            createdAt\n          }\n        }\n      }\n    }\n  }\n": types.ApplicationFilesDocument,
    "\n  query ClaimEmail($siteId: ID!, $claimId: String!) {\n    site(id: $siteId) {\n      id\n      applicantEmail(claimId: $claimId)\n    }\n  }\n": types.ClaimEmailDocument,
    "\n  mutation SendTaskEvent($taskId: ID!, $event: TaskEvent!) {\n    sendTaskEvent(taskId: $taskId, event: $event)\n  }\n": types.SendTaskEventDocument,
    "\n  mutation DuplicateApplicationFile(\n    $applicationFileId: ID!\n    $sendInvitation: Boolean\n    $wait: Boolean\n  ) {\n    duplicateApplicationFile(\n      applicationFileId: $applicationFileId\n      sendInvitation: $sendInvitation\n      wait: $wait\n    )\n  }\n": types.DuplicateApplicationFileDocument,
    "\n  query LastCreatedApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n          }\n        }\n      }\n    }\n  }\n": types.LastCreatedApplicationFileDocument,
    "\n  query LatestApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n            lastAccessedAt\n          }\n        }\n      }\n    }\n  }\n": types.LatestApplicationFileDocument,
    "\n  query ApplicationFilePrimaryAgent($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      primaryAgent {\n        id\n        name\n        email\n        phone\n        jobTitle\n        avatarUrl\n        license\n      }\n    }\n  }\n": types.ApplicationFilePrimaryAgentDocument,
    "\n  mutation SendVerificationEmail(\n    $siteId: ID\n    $typeName: String!\n    $email: String!\n    $force: Boolean!\n  ) {\n    emailVerification {\n      sendVerificationEmail(siteId: $siteId, typeName: $typeName, email: $email, force: $force) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n": types.SendVerificationEmailDocument,
    "\n  query SiteAgentData($siteId: ID!, $id: ID, $slug: String) {\n    site(id: $siteId) {\n      id\n      agent(id: $id, slug: $slug) {\n        name\n        email\n        phone\n        license\n        licensedStates\n        bio\n        jobTitle\n        facebookUrl\n        linkedinUrl\n        twitterUrl\n        avatarUrl\n        visible\n      }\n    }\n  }\n": types.SiteAgentDataDocument,
    "\n  query SiteApplicant($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        email\n        notificationOptIns\n        notificationPhoneNumber\n      }\n    }\n  }\n": types.SiteApplicantDocument,
    "\n  query Site($domain: ID!) {\n    site(id: $domain) {\n      id\n      name\n      partnerId\n      partnerName\n      liveSite\n    }\n  }\n": types.SiteDocument,
    "\n  query AnonymousSessionApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            answers\n          }\n        }\n      }\n    }\n  }\n": types.AnonymousSessionApplicationFileDocument,
    "\n  mutation TouchApplicationFile($applicationFileId: ID!) {\n    touchApplicationFile(applicationFileId: $applicationFileId) {\n      id\n    }\n  }\n": types.TouchApplicationFileDocument,
    "\n  mutation TouchTask($taskId: ID!) {\n    touchTask(taskId: $taskId) {\n      ...ITaskFragment\n    }\n  }\n": types.TouchTaskDocument,
    "\n  query Me {\n    me {\n      id\n      createdAt\n      lastLoginAt\n      loginCount\n      email\n      name\n      phoneNumber\n      jobTitle\n    }\n  }\n": types.MeDocument,
    "\n  mutation VerifyEmailByCode($siteId: ID, $email: String!, $code: String!) {\n    emailVerification {\n      verifyEmailByCode(siteId: $siteId, email: $email, code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n": types.VerifyEmailByCodeDocument,
    "\n  mutation VerifyEmailByLink($code: String!) {\n    emailVerification {\n      verifyEmailByLink(code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n": types.VerifyEmailByLinkDocument,
    "\n  query IApplicationFilesDocuments($applicationFileId: ID!, $types: [DocumentType!]) {\n    applicationFile(id: $applicationFileId) {\n      id\n      documents(types: $types, options: { perPage: 10000, orderBy: { created_at: \"desc\" } }) {\n        items {\n          id\n          filename\n          size\n          description\n          createdAt\n          url\n          type\n          task {\n            id\n            meta {\n              title\n            }\n            isVisible\n            primaryApplicantId\n            common\n          }\n          uploadedBy {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.IApplicationFilesDocumentsDocument,
    "\n  query CanRequestPassword {\n    me {\n      id\n      canResetPassword\n    }\n  }\n": types.CanRequestPasswordDocument,
    "\n  mutation ResetPasswordApplicant($data: ResetPasswordInput!) {\n    resetPassword(data: $data) {\n      status\n      message\n    }\n  }\n": types.ResetPasswordApplicantDocument,
    "\n  query QAgreeToTerms {\n    me {\n      id\n      agreedToTermsAt\n    }\n  }\n": types.QAgreeToTermsDocument,
    "\n  mutation AgreeToTermsMutation {\n    agreeToApplicantTerms\n  }\n": types.AgreeToTermsMutationDocument,
    "\n        query CustomizationValues(\n          $domain: String!\n          $namespaces: [String!]!\n          $languages: [String!]!\n        ) {\n          customizations(domain: $domain, namespaces: $namespaces, languages: $languages) {\n            id\n            key\n            namespace\n            value\n            language\n          }\n        }\n      ": types.CustomizationValuesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Name {\n    me {\n      id\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  query Name {\n    me {\n      id\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment IApplicantFragment on Applicant {\n    id\n    firstName\n    lastName\n    position\n    email\n    emailVerified\n  }\n"): (typeof documents)["\n  fragment IApplicantFragment on Applicant {\n    id\n    firstName\n    lastName\n    position\n    email\n    emailVerified\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFileApplicants($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      applicants(options: { perPage: 1000 }) {\n        items {\n          ...IApplicantFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFileApplicants($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      applicants(options: { perPage: 1000 }) {\n        items {\n          ...IApplicantFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFileConfiguredIntegrations($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      configuredIntegrations\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFileConfiguredIntegrations($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      configuredIntegrations\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFileMilestones($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      milestones {\n        id\n        key\n        name\n        active\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFileMilestones($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      milestones {\n        id\n        key\n        name\n        active\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ITaskFragment on Task {\n    id\n    key\n    typeName\n    title\n    state\n    isAlerted\n    isFrozen\n    isNew\n    isVisible\n    isStarted\n    common\n    custom\n    blueprintIndex\n    alertMessage\n    primaryApplicantId\n    meta {\n      title\n      requiresAuthentication\n      requiresEmailVerification\n      description {\n        default\n        open\n        locked\n        completed\n      }\n      disclaimer\n      groupKey\n      nextButtonLabelWhenCompleted\n      customCompletion\n      completionDescription\n      completionContinueButtonLabel\n    }\n    dueDate\n  }\n"): (typeof documents)["\n  fragment ITaskFragment on Task {\n    id\n    key\n    typeName\n    title\n    state\n    isAlerted\n    isFrozen\n    isNew\n    isVisible\n    isStarted\n    common\n    custom\n    blueprintIndex\n    alertMessage\n    primaryApplicantId\n    meta {\n      title\n      requiresAuthentication\n      requiresEmailVerification\n      description {\n        default\n        open\n        locked\n        completed\n      }\n      disclaimer\n      groupKey\n      nextButtonLabelWhenCompleted\n      customCompletion\n      completionDescription\n      completionContinueButtonLabel\n    }\n    dueDate\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFileTasks($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      tasks {\n        ...ITaskFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFileTasks($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      tasks {\n        ...ITaskFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFile($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      voeIntegrationType\n      voaIntegrationType\n      milestones {\n        id\n        key\n        name\n        active\n      }\n      applicants {\n        items {\n          ...IApplicantFragment\n        }\n      }\n      tasks {\n        ...ITaskFragment\n      }\n      blueprint {\n        id\n        json\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFile($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      voeIntegrationType\n      voaIntegrationType\n      milestones {\n        id\n        key\n        name\n        active\n      }\n      applicants {\n        items {\n          ...IApplicantFragment\n        }\n      }\n      tasks {\n        ...ITaskFragment\n      }\n      blueprint {\n        id\n        json\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFiles($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            detailedDescription\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFiles($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            detailedDescription\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClaimEmail($siteId: ID!, $claimId: String!) {\n    site(id: $siteId) {\n      id\n      applicantEmail(claimId: $claimId)\n    }\n  }\n"): (typeof documents)["\n  query ClaimEmail($siteId: ID!, $claimId: String!) {\n    site(id: $siteId) {\n      id\n      applicantEmail(claimId: $claimId)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendTaskEvent($taskId: ID!, $event: TaskEvent!) {\n    sendTaskEvent(taskId: $taskId, event: $event)\n  }\n"): (typeof documents)["\n  mutation SendTaskEvent($taskId: ID!, $event: TaskEvent!) {\n    sendTaskEvent(taskId: $taskId, event: $event)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DuplicateApplicationFile(\n    $applicationFileId: ID!\n    $sendInvitation: Boolean\n    $wait: Boolean\n  ) {\n    duplicateApplicationFile(\n      applicationFileId: $applicationFileId\n      sendInvitation: $sendInvitation\n      wait: $wait\n    )\n  }\n"): (typeof documents)["\n  mutation DuplicateApplicationFile(\n    $applicationFileId: ID!\n    $sendInvitation: Boolean\n    $wait: Boolean\n  ) {\n    duplicateApplicationFile(\n      applicationFileId: $applicationFileId\n      sendInvitation: $sendInvitation\n      wait: $wait\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LastCreatedApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LastCreatedApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LatestApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n            lastAccessedAt\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query LatestApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 200, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            createdAt\n            lastAccessedAt\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationFilePrimaryAgent($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      primaryAgent {\n        id\n        name\n        email\n        phone\n        jobTitle\n        avatarUrl\n        license\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationFilePrimaryAgent($applicationFileId: ID!) {\n    applicationFile(id: $applicationFileId) {\n      id\n      primaryAgent {\n        id\n        name\n        email\n        phone\n        jobTitle\n        avatarUrl\n        license\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SendVerificationEmail(\n    $siteId: ID\n    $typeName: String!\n    $email: String!\n    $force: Boolean!\n  ) {\n    emailVerification {\n      sendVerificationEmail(siteId: $siteId, typeName: $typeName, email: $email, force: $force) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendVerificationEmail(\n    $siteId: ID\n    $typeName: String!\n    $email: String!\n    $force: Boolean!\n  ) {\n    emailVerification {\n      sendVerificationEmail(siteId: $siteId, typeName: $typeName, email: $email, force: $force) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteAgentData($siteId: ID!, $id: ID, $slug: String) {\n    site(id: $siteId) {\n      id\n      agent(id: $id, slug: $slug) {\n        name\n        email\n        phone\n        license\n        licensedStates\n        bio\n        jobTitle\n        facebookUrl\n        linkedinUrl\n        twitterUrl\n        avatarUrl\n        visible\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteAgentData($siteId: ID!, $id: ID, $slug: String) {\n    site(id: $siteId) {\n      id\n      agent(id: $id, slug: $slug) {\n        name\n        email\n        phone\n        license\n        licensedStates\n        bio\n        jobTitle\n        facebookUrl\n        linkedinUrl\n        twitterUrl\n        avatarUrl\n        visible\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteApplicant($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        email\n        notificationOptIns\n        notificationPhoneNumber\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteApplicant($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        email\n        notificationOptIns\n        notificationPhoneNumber\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Site($domain: ID!) {\n    site(id: $domain) {\n      id\n      name\n      partnerId\n      partnerName\n      liveSite\n    }\n  }\n"): (typeof documents)["\n  query Site($domain: ID!) {\n    site(id: $domain) {\n      id\n      name\n      partnerId\n      partnerName\n      liveSite\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AnonymousSessionApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            answers\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AnonymousSessionApplicationFile($siteId: ID!, $applicantId: ID!) {\n    site(id: $siteId) {\n      id\n      applicant(id: $applicantId) {\n        id\n        applicationFiles(options: { perPage: 1, orderBy: { created_at: \"desc\" } }) {\n          items {\n            id\n            answers\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TouchApplicationFile($applicationFileId: ID!) {\n    touchApplicationFile(applicationFileId: $applicationFileId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation TouchApplicationFile($applicationFileId: ID!) {\n    touchApplicationFile(applicationFileId: $applicationFileId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TouchTask($taskId: ID!) {\n    touchTask(taskId: $taskId) {\n      ...ITaskFragment\n    }\n  }\n"): (typeof documents)["\n  mutation TouchTask($taskId: ID!) {\n    touchTask(taskId: $taskId) {\n      ...ITaskFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Me {\n    me {\n      id\n      createdAt\n      lastLoginAt\n      loginCount\n      email\n      name\n      phoneNumber\n      jobTitle\n    }\n  }\n"): (typeof documents)["\n  query Me {\n    me {\n      id\n      createdAt\n      lastLoginAt\n      loginCount\n      email\n      name\n      phoneNumber\n      jobTitle\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation VerifyEmailByCode($siteId: ID, $email: String!, $code: String!) {\n    emailVerification {\n      verifyEmailByCode(siteId: $siteId, email: $email, code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmailByCode($siteId: ID, $email: String!, $code: String!) {\n    emailVerification {\n      verifyEmailByCode(siteId: $siteId, email: $email, code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation VerifyEmailByLink($code: String!) {\n    emailVerification {\n      verifyEmailByLink(code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmailByLink($code: String!) {\n    emailVerification {\n      verifyEmailByLink(code: $code) {\n        success\n        error_code\n        error_message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query IApplicationFilesDocuments($applicationFileId: ID!, $types: [DocumentType!]) {\n    applicationFile(id: $applicationFileId) {\n      id\n      documents(types: $types, options: { perPage: 10000, orderBy: { created_at: \"desc\" } }) {\n        items {\n          id\n          filename\n          size\n          description\n          createdAt\n          url\n          type\n          task {\n            id\n            meta {\n              title\n            }\n            isVisible\n            primaryApplicantId\n            common\n          }\n          uploadedBy {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query IApplicationFilesDocuments($applicationFileId: ID!, $types: [DocumentType!]) {\n    applicationFile(id: $applicationFileId) {\n      id\n      documents(types: $types, options: { perPage: 10000, orderBy: { created_at: \"desc\" } }) {\n        items {\n          id\n          filename\n          size\n          description\n          createdAt\n          url\n          type\n          task {\n            id\n            meta {\n              title\n            }\n            isVisible\n            primaryApplicantId\n            common\n          }\n          uploadedBy {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CanRequestPassword {\n    me {\n      id\n      canResetPassword\n    }\n  }\n"): (typeof documents)["\n  query CanRequestPassword {\n    me {\n      id\n      canResetPassword\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPasswordApplicant($data: ResetPasswordInput!) {\n    resetPassword(data: $data) {\n      status\n      message\n    }\n  }\n"): (typeof documents)["\n  mutation ResetPasswordApplicant($data: ResetPasswordInput!) {\n    resetPassword(data: $data) {\n      status\n      message\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QAgreeToTerms {\n    me {\n      id\n      agreedToTermsAt\n    }\n  }\n"): (typeof documents)["\n  query QAgreeToTerms {\n    me {\n      id\n      agreedToTermsAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AgreeToTermsMutation {\n    agreeToApplicantTerms\n  }\n"): (typeof documents)["\n  mutation AgreeToTermsMutation {\n    agreeToApplicantTerms\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query CustomizationValues(\n          $domain: String!\n          $namespaces: [String!]!\n          $languages: [String!]!\n        ) {\n          customizations(domain: $domain, namespaces: $namespaces, languages: $languages) {\n            id\n            key\n            namespace\n            value\n            language\n          }\n        }\n      "): (typeof documents)["\n        query CustomizationValues(\n          $domain: String!\n          $namespaces: [String!]!\n          $languages: [String!]!\n        ) {\n          customizations(domain: $domain, namespaces: $namespaces, languages: $languages) {\n            id\n            key\n            namespace\n            value\n            language\n          }\n        }\n      "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;