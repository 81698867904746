import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { type ApplicationFileTasksQuery } from '../apollo/graphql';

export const TaskFragment = graphql(`
  fragment ITaskFragment on Task {
    id
    key
    typeName
    title
    state
    isAlerted
    isFrozen
    isNew
    isVisible
    isStarted
    common
    custom
    blueprintIndex
    alertMessage
    primaryApplicantId
    meta {
      title
      requiresAuthentication
      requiresEmailVerification
      description {
        default
        open
        locked
        completed
      }
      disclaimer
      groupKey
      nextButtonLabelWhenCompleted
      customCompletion
      completionDescription
      completionContinueButtonLabel
    }
    dueDate
  }
`);

const QApplicationFileTasks = graphql(`
  query ApplicationFileTasks($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      tasks {
        ...ITaskFragment
      }
    }
  }
`);

export type IApplicationFileTask = NonNullable<
  ApplicationFileTasksQuery['applicationFile']
>['tasks'][0];

export const useApplicationFileTasks = (applicationFileId: string) => {
  const [data, loading] = useQuery(
    QApplicationFileTasks,
    {
      applicationFileId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const result: IApplicationFileTask[] = data?.applicationFile?.tasks || [];

  return [result, loading] as const;
};
