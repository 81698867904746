import { Button, HtmlRenderer, spacing, styled } from '@mortgagehippo/ds';
import { type ITask } from '@mortgagehippo/tasks';
import { trim } from 'lodash-es';
import { useCallback, useState } from 'react';
import { Redirect } from 'react-router';

import { type IApplicationFile } from '../../hooks/use-application-file';
import { findNextTask } from '../../services/find-next-task';
import { history } from '../../services/history';
import { TaskSuccess } from './task-success';

const Container = styled.div`
  text-align: center;
  padding: ${spacing(4)} 0 ${spacing(4)};

  .public-DraftStyleDefault-ltr {
    text-align: center;
  }
`;

const CompleteButtonContainer = styled.div`
  padding: ${spacing(5)} 0 0;
  text-align: center;
`;

export interface ITaskCompletedProps {
  task: ITask;
  applicationFile: IApplicationFile;
  currentTaskId: string;
  applicantId: string;
}

export const TaskCompleted = (props: ITaskCompletedProps) => {
  const { task, applicationFile, currentTaskId } = props;
  const [nextTask] = findNextTask(applicationFile, currentTaskId);
  const [timerDone, setTimerDone] = useState(false);
  const { customCompletion, completionContinueButtonLabel, completionDescription } = task?.meta || {};
  const url =
    (nextTask && `/applications/${applicationFile.id}/tasks/${nextTask.id}`) ||
    `/applications/${applicationFile.id}`;

  const handleFinish = useCallback(() => {
    setTimerDone(true);
  }, [setTimerDone]);

  const handleGoToTask = () => {
    history.push(url);
  };

  return (
    <div>
    {customCompletion ? (
      <Container>
        <HtmlRenderer value={trim(completionDescription)} />
        <CompleteButtonContainer>
          <Button onClick={handleGoToTask} importance="primary" icon="right-arrow" iconLocation="right">
            {completionContinueButtonLabel || 'Continue'}
          </Button>
        </CompleteButtonContainer>
      </Container>
    ) : (
      <>
        <TaskSuccess onFinish={handleFinish} startDelayMs={0} finishDelayMs={200} />
        {timerDone ? <Redirect to={url} /> : null}
      </>
    )}
    </div>
  );
};
